.App {
    overflow: hidden;
}

.slick-slide {
    pointer-events: auto !important;
}

.color-black {
    color: #000000 !important;
}

.color-light-grey {
    color: #BFBFBF !important;
}

.color-warning {
    color: #ffc542 !important;
}

.color-blue {
    color: #0958d9 !important;
}

.color-cyan {
    color: #08979c !important;
}

.color-primary {
    color: #004771 !important;
}

.color-secondary {
    color: #3E3737 !important;
}

.color-white {
    color: #ffffff !important;
}

.color-green {
    color: #019E4E !important;
}

.color-red {
    color: red !important;
}

.color-remove {
    color: #b32d2e !important;
}

.color-text {
    color: #605656 !important;
}

.bg-color-green {
    background-color: #3da07b !important;
    /* background-color: #00a064 !important; */
}

.bg-color-warning {
    background-color: #ffc542 !important;
}

.font-weight-600 {
    font-weight: 600;
}

.font-weight-700 {
    font-weight: 700;
}

.text-align-center {
    text-align: center;
}

.model-close-btn {
    background-color: #fff;
}

.model-body {
    margin-top: 25px;
}

.disabled {
    opacity: 0.2;
}

.not-disabled {
    opacity: 1;
}

.upload-file-btn {
    border: 1px solid #e6ebf1;
    border-radius: 6px;
}

.file-select-text {
    padding-left: 10px;
    line-height: 1.5;
}

ul.file-list>li {
    list-style: none;
    border: 1px solid #e6ebf1;
    border-radius: 12px;
    padding: 8px;
    margin-top: 8px;
}

img.file-preview-img {
    width: 50px;
    height: 50px;
}

.show-more-button-underline>span,
.underline-detail-btn>span,
.underline-detail-btn {
    text-decoration: underline;
}

.submit-btn {
    background-color: #004771;
    color: #fff;
    padding: 10px 50px;
    border-radius: 4px;
    margin: 0 0 10px 0;
    font-size: 14px;
    box-shadow: unset;
}

.submit-btn-reject {
    background-color: red;
}

.button {
    border-radius: 2px;
    box-shadow: none;
    padding: 10px 15px;
}

.button:disabled {
    color: #fff;
    background-color: #004771;
    opacity: 50%;
}

.main-body {
    background-color: #ffffff;
}

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    content: "" !important;
}

.ant-form-item .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    display: inline-block;
    color: #ff6b72;
    font-size: 14px;
    line-height: 1;
    content: "*";
}

.width-hundred {
    width: 100%;
}

.ant-input,
.ant-select-selector,
.ant-input-affix-wrapper,
.ant-btn,
.ant-input-group-addon,
.ant-picker,
.ant-input-number:not(.ant-input-number-group .ant-input-number) {
    border-radius: 3px !important;
}

.ant-input-number-group-addon {
    border-radius: 3px 0 0 3px !important;
}

.ant-input-number-group .ant-input-number {
    border-radius: 0 3px 3px 0 !important;
}

.ant-tooltip-arrow {
    --antd-arrow-background-color: white !important;
}

.ant-tooltip-inner {
    background-color: white !important;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -11px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05) !important;
    padding: 15px 10px !important;
}

.ant-tooltip-inner strong {
    color: #004771 !important;
}

.ant-tooltip-inner p {
    color: #3E3737;
}

.ant-tooltip-inner {
    color: #3E3737 !important;
}

.error-message-invalid {
    background-color: #FCEAEA;
    border-radius: 50px;
    margin: 10px 0;
    padding: 8px 20px;
}

.error-message-invalid>p {
    color: #E83A3A;
    margin: 4px 0 0 10px;
}

.success-message {
    background-color: #E0FFEF;
    border-radius: 50px;
    margin: 10px 0;
    padding: 8px 20px;
}

.success-message>p {
    color: #019E4E;
    margin: 4px 0 0 10px;
}

:where(.css-dev-only-do-not-override-11qzz7t) .ant-form-item .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    margin-inline-end: 0;
}

.cursor-pointer {
    cursor: pointer;
}

.hidden {
    display: none;
}

/* Packages css start */
.carousel-packages-content-inner {
    padding: 20px 0;
}

.pkg-subtitle {
    line-height: 1.5;
}

.carousel-packages {
    margin: 0 35px;
    padding: 20px;
}

.carousel-packages-content {
    color: #fff;
    margin: 5px;
    text-align: center;
    border-radius: 5px;
    padding: 20px;
    box-shadow: 0px 0px 15px 0px #0000001A;
    /* cursor: pointer; */
    background-color: #fff;
}

.carousel-package-selected {
    border: 1px solid #004771;
}

.request-package-selected {
    border: 1px solid #ffc542;
}

.current-package-selected {
    border: 1px solid #3da07b;
}

.carousel-packages-content-inner-text {
    font-size: 16px;
}

.pkg-title {
    font-weight: 600;
    line-height: 1.5;
}

.pkg-center {
    text-align: center;
}

.carousel-packages-content-inner-text-price {
    font-weight: 600;
    font-size: 25px;
}

.carousel-packages-content-inner-separator-img {
    height: 1px;
    margin: 10px 0 10px 0;
}

.carousel-packages-content-inner-feature-text {
    font-size: 14px;
    color: #3E3737;
    margin-left: 10px;
    margin-bottom: 0;
    text-align: left;
}

.btn-package-unselected {
    background-color: #E0E0E0;
    color: #004771;
    padding: 10px;
    border-radius: 4px;
    margin-top: 20px;
    font-size: 14px;
}

.btn-package-selected {
    background-color: #004771;
    color: #fff;
    padding: 10px;
    border-radius: 4px;
    margin-top: 20px;
    font-size: 14px;
}


.carousel-packages>.slick-prev,
.carousel-packages>.slick-next {
    height: 30px !important;
    width: 30px !important;
}

.included-package-img {
    width: 22px;
}

@media (min-width: 320px) and (max-width: 767px) {
    .file-select-text {
        margin-top: 10px;
        padding-left: unset !important;
    }
}

@media (min-width: 320px) and (max-width: 374px) {

    .carousel-packages {
        padding: unset;
        margin: 20px;
    }

    .carousel-packages-content-inner-text {
        font-size: 12px;
    }

    .carousel-packages-content-inner-text-price {
        font-size: 15px;
    }

    .included-package-img {
        width: 17px;
    }

    .carousel-packages-content-inner-feature-text {
        font-size: 8px;
    }

    .btn-package-unselected {
        padding: 8px;
    }
}

@media (min-width: 375px) and (max-width: 424px) {

    .carousel-packages {
        padding: unset;
        margin: 20px;
    }

    .included-package-img {
        width: 20px;
    }

    .carousel-packages-content-inner-feature-text {
        font-size: 13px;
    }

    .btn-package-unselected {
        padding: 8px;
    }
}

@media (min-width: 425px) and (max-width: 767px) {

    .carousel-packages {
        padding: 10px;
        margin: 20px;
    }
}

@media (min-width: 768px) and (max-width: 1199px) {

    .carousel-packages {
        padding: 10px;
        margin: 0 20px;
    }

    .carousel-packages-content-inner-text-price {
        font-size: 18px;
    }

    .included-package-img {
        width: 17px;
    }

    .carousel-packages-content-inner-feature-text {
        font-size: 9px;
    }
}

@media (min-width: 320px) and (max-width: 1199px) {
    .wmt-100 {
        width: 100px;
    }
}

@media (min-width: 1200px) and (max-width: 1470px) {
    .carousel-packages-content-inner-text {
        font-size: 12px;
    }

    .carousel-packages-content-inner-text-price {
        font-size: 13px;
    }
}

@media (min-width: 1200px) and (max-width: 1650px) {
    .included-package-img {
        width: 20px;
    }

    .carousel-packages-content-inner-feature-text {
        font-size: 13px;
    }

    .included-package-img {
        width: 16px;
    }
}

/* Packages css end */
/* Add Scroll css start */
.scroll-list {
    overflow-y: auto;
    overflow-x: hidden;
}

.scroll-height {
    height: 250px;
}

.carousel-projects>div>div>div {
    width: 50px !important;
}

.scroll-list::-webkit-scrollbar {
    width: 4px;
}

.scroll-list::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #CBCBCB;
    border-radius: 5px;
}

.scroll-list::-webkit-scrollbar-track {
    border-radius: 10px;
    background: #ECECEC;
}

/* Add Scroll css end */
.carousel-projects-selected-img {
    border: 1px solid #004771;
    border-radius: 50px;
}

.carousel-projects-img {
    cursor: pointer;
    width: 45px;
    height: 45px;
    border-radius: 50px;
}

.carousel-projects-adjustment {
    margin: 0 35px;
    padding: 20px;
}

/* .carousel-projects {
    margin: 0 35px;
    padding: 20px;
} */

.carousel-projects>.slick-prev,
.carousel-projects>.slick-next {
    height: 15px !important;
    width: 15px !important;
}

@media (min-width: 320px) and (max-width: 374px) {

    .carousel-projects {
        padding: unset;
        margin: 20px;
    }
}

@media (min-width: 375px) and (max-width: 424px) {

    .carousel-projects {
        padding: unset;
        margin: 20px;
    }
}

@media (min-width: 425px) and (max-width: 767px) {

    .carousel-projects {
        padding: 10px;
        margin: 20px;
    }
}

@media (min-width: 768px) and (max-width: 1199px) {

    .carousel-projects {
        padding: 10px;
        margin: 0 20px;
    }
}

@media (min-width: 1200px) and (max-width: 1650px) {
    .carousel-projects {
        padding: 10px;
        margin: 0 20px;
    }
}

/* Utils css start */
@media (min-width: 1650px) {
    .included-package-img-xl {
        width: 20px;
    }

    .carousel-packages-content-inner-feature-text-xl {
        font-size: 9px;
    }
}

/* Utils css end */